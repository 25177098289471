<template>
  <div>
    <HeaderNavHomepage />

    <main class="main-wrapper">
      <div class="main-card">
        <section class="hero">
          <h1>
            HelixPay Enhances Events through
            <span class="nowrap">On-Demand</span> ID Badges: <br />A
            <span class="nowrap"> Game-Changer for Organizers </span>
          </h1>
          <div class="post-date">September 16, 2024</div>
        </section>

        <div class="main">
          <div class="image-container">
            <VLazyImage src="/img/articles/id-printing-1.jpg" />
          </div>

          <p>
            HelixPay’s on-demand printing of ID badges is transforming event
            management by streamlining registration and enhancing networking.
            With successful deployments at high-profile events like the
            Corporate Giveaways Buyers Show and the Merged Filipino-Chinese Job
            Fair, which together saw over 8,000 registrations, as well as the
            Philippine Game Dev Expo with 5,000 registrations, this feature has
            been highly praised for its convenience and efficiency.
          </p>

          <p>
            Utilizing thermal printers and stickers, HelixPay allows event
            organizers to produce ID badges in real time, greatly reducing the
            hassle of pre-printing and enabling instant customization.
          </p>

          <h2>Why on-Demand ID Printing Matters</h2>

          <p>
            HelixPay’s on-demand ID printing doesn’t just streamline
            registration—it also serves crucial identification needs, tailored
            specifically to the requirements of each event. By enabling the
            immediate production of ID badges, event organizers can ensure that
            key participants are easily identifiable, improving interaction and
            organization.
          </p>

          <p>
            At the <strong>Merged Filipino-Chinese Job Fair</strong>, for
            instance, ID badges were primarily used by employers, helping job
            seekers quickly recognize recruiters from various companies. This
            increased efficiency in networking and allowed candidates to
            approach the right employers with ease. The convenience of printing
            badges on the spot eliminated the need for pre-prepared materials,
            providing a seamless experience for both sides.
          </p>

          <p>
            A similar impact was seen at the
            <strong>Corporate Giveaways Buyers’ Show</strong>, where ID badges
            streamlined interactions between exhibitors (giveaway suppliers) and
            attendees (company representatives). The badges also helped
            exhibitors easily track which companies showed interest in their
            products, making the process more efficient.
          </p>

          <p>
            Finally for the <strong>Philippine Game Dev Expo</strong>, on-demand
            ID badges were issued to workshop attendees, allowing organizers to
            clearly differentiate them from general participants. This helped
            streamline workshop logistics and ensured smooth access to
            designated areas.
          </p>

          <p>
            As more event organizers discover the benefits of
            <strong>on-demand ID badge printing</strong>, HelixPay continues to
            improve this service. By enhancing identification, improving
            networking, and streamlining event logistics, this feature is set to
            become an essential tool for more clients across industries.
          </p>

          <p>
            Looking to streamline your event registration and networking?
            Contact us at
            <a href="mailto:hello@helixpay.ph">hello@helixpay.ph</a> and
            discover how on-demand ID printing can enhance your next event.
          </p>
        </div>
      </div>
    </main>

    <MainCCFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import VLazyImage from 'v-lazy-image';

const metaImagePath = 'img/articles/id-printing-1.jpg';

const articlePath =
  '/articles/helix-pay-enhances-events-through-on-demand-id-badges-a-game-changer-for-organizers';

useHead(
  buildPageMeta({
    title: `HelixPay Enhances Events through On-Demand ID Badges: A Game-Changer for Organizers`,
    description: `HelixPay’s on-demand printing of ID badges is transforming event management by streamlining registration and enhancing networking.`,
    url: `https://www.helixpay.ph/${articlePath}`,
    image: `https://www.helixpay.ph/${metaImagePath}`,
  })
);
</script>

<style scoped>
.main-wrapper {
  background-image: url('/img/articles/id-printing-2.png');
  padding: 2rem 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #94c6bb;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.main-card {
  background: white;
  margin: 0 auto;
  max-width: 880px;
  border-radius: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);
}

.hero {
  padding: 4rem 1.5rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0 auto;
  font-size: 1.15rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  font-weight: 700;
  max-width: 1080px;
}

.image-container {
  padding-bottom: 100%;
  background: #b5c9c4;
  position: relative;
}

.image-container img {
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.main strong {
  font-weight: 600;
}

.main p,
.main h2 {
  margin: 2rem 1.5rem;
}

.main p {
  font-size: 0.9rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .main-wrapper {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero {
    padding: 6rem 3rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .main p,
  .main h2 {
    margin: 3rem auto;
    max-width: 512px;
  }

  .main p {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .hero h1 {
    font-size: 2rem;
  }

  .main p,
  .main h2 {
    max-width: 610px;
  }

  .main p {
    font-size: 1.15rem;
  }
}

.nowrap {
  white-space: nowrap;
}

.post-date {
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.7;
}

a {
  color: #25a4e1;
}
</style>
